import React, { useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CImage,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilUser } from '@coreui/icons'
import Image from '../../../assets/images/main_logo.png'
import axios from 'axios'
import baseUrl from 'src/Base_Url/baseUrl'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: '',
  })

  const navigate = useNavigate()
  const toast = useToast()

  const [loading, setLoading] = useState(false)

  const resetPassword = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      const response = await axios.put(`${baseUrl}/auth/resetPassword`, formData)
      if (response) {
        setLoading(false)
        const message = response.data.data.message
        toast({
          title: typeof message === 'string' ? message : 'Password reset successful',
          description: 'Check your email to get new password.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setTimeout(() => {
          navigate('/#/analytics')
        }, 500)
      }
    } catch (error) {
      setLoading(false)
      const errorMessage = error.response.data.data
      toast({
        title: typeof errorMessage === 'string' ? errorMessage : 'An error occurred',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-column align-items-center justify-content-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6} lg={4}>
            <CCard className="text-white mb-4" style={{ backgroundColor: '' }}>
              <CCardBody className="text-center">
                <CImage
                  src={Image}
                  alt="Description of the image"
                  fluid
                  width={'100%'}
                  height={'auto'}
                />
              </CCardBody>
            </CCard>
            <CCard className="p-4">
              <CCardBody>
                <CForm onSubmit={resetPassword}>
                  <h1>Reset Password !</h1>
                  <p className="text-medium-emphasis">Enter your registered email</p>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput name="email" placeholder="Email" onChange={handleInputChange} />
                  </CInputGroup>
                  <CRow>
                    <CButton type="submit" color="primary" className="px-4">
                      {loading ? (
                        <div className="spinner-border text-white" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <span>Get Password</span>
                      )}
                    </CButton>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ForgotPassword
